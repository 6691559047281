import axios from 'axios';

export const isAuthenticated = async () => {
  try {
    const response = await axios.get('https://api.argustransport.com/protected-route', { withCredentials: true });
    return response.status === 200;
  } catch (error) {
    return false;
  }
};
