import React, { useState, useEffect } from 'react';
import { getDrivers, getTrucks, getTrailers, createRoute } from '../services/api';
import socket from '../services/socket';
import './RouteForm.css';

function RouteForm() {
  const [tripNumber, setTripNumber] = useState('');
  const [driverId, setDriverId] = useState('');
  const [truckId, setTruckId] = useState('');
  const [trailerId, setTrailerId] = useState('');
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [drivers, setDrivers] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [trailers, setTrailers] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const driversResponse = await getDrivers();
      setDrivers(driversResponse.data);

      const trucksResponse = await getTrucks();
      setTrucks(trucksResponse.data);

      const trailersResponse = await getTrailers();
      setTrailers(trailersResponse.data);
    }
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createRoute(tripNumber, driverId, truckId, date, trailerId);
      setTripNumber('');
      setDriverId('');
      setTruckId('');
      setTrailerId('');
      setDate(new Date().toISOString().split('T')[0]);
      socket.emit('new_route', response.data); // Emit event after creating a route
    } catch (error) {
      console.error('Error creating route:', error);
    }
  };

  return (
    <div className="route-form-container">
      <h1>Create Route</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Trip Number:
            <input type="text" value={tripNumber} onChange={(e) => setTripNumber(e.target.value)} />
          </label>
        </div>
        <div className="form-group">
          <label>Driver:
            <select value={driverId} onChange={(e) => setDriverId(e.target.value)}>
              <option value="">Select Driver</option>
              {drivers.map(driver => (
                <option key={driver.id} value={driver.id}>{driver.name}</option>
              ))}
            </select>
          </label>
        </div>
        <div className="form-group">
          <label>Truck:
            <select value={truckId} onChange={(e) => setTruckId(e.target.value)}>
              <option value="">Select Truck</option>
              {trucks.map(truck => (
                <option key={truck.id} value={truck.id}>{truck.number}</option>
              ))}
            </select>
          </label>
        </div>
        <div className="form-group">
          <label>Trailer (Optional):
            <select value={trailerId} onChange={(e) => setTrailerId(e.target.value)}>
              <option value="">Select Trailer</option>
              {trailers.map(trailer => (
                <option key={trailer.id} value={trailer.id}>{trailer.number}</option>
              ))}
            </select>
          </label>
        </div>
        <div className="form-group">
          <label>Date:
            <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
          </label>
        </div>
        <button type="submit">Create Route</button>
      </form>
    </div>
  );
}

export default RouteForm;
