import { io } from 'socket.io-client';

const socket = io('https://papsums.argustransport.com', {
  path: '/ws',
  transports: ['websocket'],
  secure: true,
  rejectUnauthorized: false,
});

socket.on('connect', () => {
  console.log('Connected to WebSocket server');
});

socket.on('disconnect', () => {
  console.log('Disconnected from WebSocket server');
});

export default socket;
