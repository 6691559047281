import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import DriverForm from './components/DriverForm';
import TruckForm from './components/TruckForm';
import TrailerForm from './components/TrailerForm';
import RouteForm from './components/RouteForm';
import ShipmentForm from './components/ShipmentForm';
import LoginForm from './components/LoginForm';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={LoginForm} />
        <ProtectedRoute path="/dashboard" component={Dashboard} />
        <ProtectedRoute path="/drivers" component={DriverForm} />
        <ProtectedRoute path="/trucks" component={TruckForm} />
        <ProtectedRoute path="/trailers" component={TrailerForm} />
        <ProtectedRoute path="/routes" component={RouteForm} />
        <ProtectedRoute path="/shipments" component={ShipmentForm} />
        <Route path="/" exact component={LoginForm} />
      </Switch>
    </Router>
  );
}

export default App;
