// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
    padding: 2em;
    background-color: var(--background-color);
    color: var(--text-color);
    border: 1px solid var(--accent-color);
    border-radius: 5px;
    max-width: 400px;
    margin: 2em auto;
  }
  
  h1 {
    color: var(--primary-color);
  }
  
  label {
    display: block;
    margin: 1em 0 0.5em;
  }
  
  input {
    width: calc(100% - 1em);
    padding: 0.5em;
    border: 1px solid var(--accent-color);
    border-radius: 5px;
    background-color: var(--input-background-color);
    color: var(--text-color);
  }
  
  button {
    padding: 0.5em 1em;
    margin-top: 1em;
    border: none;
    border-radius: 5px;
    background-color: var(--secondary-color);
    color: var(--button-text-color);
    cursor: pointer;
  }
  
  button:hover {
    background-color: var(--highlight-color);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Login.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yCAAyC;IACzC,wBAAwB;IACxB,qCAAqC;IACrC,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,uBAAuB;IACvB,cAAc;IACd,qCAAqC;IACrC,kBAAkB;IAClB,+CAA+C;IAC/C,wBAAwB;EAC1B;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,wCAAwC;IACxC,+BAA+B;IAC/B,eAAe;EACjB;;EAEA;IACE,wCAAwC;EAC1C","sourcesContent":[".login-container {\r\n    padding: 2em;\r\n    background-color: var(--background-color);\r\n    color: var(--text-color);\r\n    border: 1px solid var(--accent-color);\r\n    border-radius: 5px;\r\n    max-width: 400px;\r\n    margin: 2em auto;\r\n  }\r\n  \r\n  h1 {\r\n    color: var(--primary-color);\r\n  }\r\n  \r\n  label {\r\n    display: block;\r\n    margin: 1em 0 0.5em;\r\n  }\r\n  \r\n  input {\r\n    width: calc(100% - 1em);\r\n    padding: 0.5em;\r\n    border: 1px solid var(--accent-color);\r\n    border-radius: 5px;\r\n    background-color: var(--input-background-color);\r\n    color: var(--text-color);\r\n  }\r\n  \r\n  button {\r\n    padding: 0.5em 1em;\r\n    margin-top: 1em;\r\n    border: none;\r\n    border-radius: 5px;\r\n    background-color: var(--secondary-color);\r\n    color: var(--button-text-color);\r\n    cursor: pointer;\r\n  }\r\n  \r\n  button:hover {\r\n    background-color: var(--highlight-color);\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
