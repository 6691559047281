import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  getRoutesByDate,
  getShipmentsByDate,
  getDrivers,
  getTrucks,
  getTrailers,
  updateShipmentRoute,
  logout,
  deleteShipment,
  updateShipment,
  updateRoute,
} from '../services/api';
import useAuth from '../hooks/useAuth';
import Modal from 'react-modal';
import socket from '../services/socket';
import './Dashboard.css';
import * as XLSX from 'xlsx';  // Import the xlsx library

Modal.setAppElement('#root');

function Dashboard() {
  const isAuthenticated = useAuth();

  const [routes, setRoutes] = useState([]);
  const [shipments, setShipments] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [trailers, setTrailers] = useState([]);
  const [waitingShipments, setWaitingShipments] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [shipmentModalIsOpen, setShipmentModalIsOpen] = useState(false);
  const [currentShipment, setCurrentShipment] = useState(null);
  const [editedDescription, setEditedDescription] = useState('');
  const [editedRouteId, setEditedRouteId] = useState(null);
  const [editedDirection, setEditedDirection] = useState('');
  const [dateModalIsOpen, setDateModalIsOpen] = useState(false);
  const [routeModalIsOpen, setRouteModalIsOpen] = useState(false);
  const [currentRoute, setCurrentRoute] = useState(null);
  const [editedTripNumber, setEditedTripNumber] = useState('');
  const [editedDriverId, setEditedDriverId] = useState('');
  const [editedTruckId, setEditedTruckId] = useState('');
  const [editedTrailerId, setEditedTrailerId] = useState('');
  const history = useHistory();
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    async function fetchData() {
      try {
        const routesResponse = await getRoutesByDate(selectedDate);
        if (isMounted.current) setRoutes(routesResponse.data);

        const shipmentsResponse = await getShipmentsByDate(selectedDate);
        if (isMounted.current) setShipments(shipmentsResponse.data);

        const driversResponse = await getDrivers();
        if (isMounted.current) setDrivers(driversResponse.data);

        const trucksResponse = await getTrucks();
        if (isMounted.current) setTrucks(trucksResponse.data);

        const trailersResponse = await getTrailers();
        if (isMounted.current) setTrailers(trailersResponse.data);

        if (isMounted.current) setWaitingShipments(shipmentsResponse.data.filter(shipment => shipment.route_id === null));
      } catch (error) {
        if (isMounted.current) console.error('Error fetching data:', error);
      }
    }

    fetchData();

    socket.on('updateData', fetchData);

    return () => {
      isMounted.current = false;
      socket.off('updateData', fetchData);
    };
  }, [selectedDate]);

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const routesResponse = await getRoutesByDate(selectedDate);
        const shipmentsResponse = await getShipmentsByDate(selectedDate);

        setRoutes(routesResponse.data);
        setShipments(shipmentsResponse.data);
        setWaitingShipments(shipmentsResponse.data.filter(shipment => shipment.route_id === null));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [selectedDate]);

  const getDriverName = (driverId) => {
    const driver = drivers.find(driver => driver.id === driverId);
    return driver ? driver.name : 'Unknown';
  };

  const getTruckNumber = (truckId) => {
    const truck = trucks.find(truck => truck.id === truckId);
    return truck ? truck.number : 'Unknown';
  };

  const getTrailerNumber = (trailerId) => {
    const trailer = trailers.find(trailer => trailer.id === trailerId);
    return trailer ? trailer.number : 'None';
  };

  const handlePrint = () => {
    window.print();
  };

  const handleDisassociate = async (shipmentId) => {
    await updateShipmentRoute(shipmentId, null);
    const updatedShipments = shipments.map(shipment =>
      shipment.id === shipmentId ? { ...shipment, route_id: null } : shipment
    );
    setShipments(updatedShipments);
    setWaitingShipments(updatedShipments.filter(shipment => shipment.route_id === null));
  };

  const handleSwitch = async (shipmentId, newRouteId) => {
    await updateShipmentRoute(shipmentId, newRouteId);
    const updatedShipments = shipments.map(shipment =>
      shipment.id === shipmentId ? { ...shipment, route_id: newRouteId } : shipment
    );
    setShipments(updatedShipments);
    setWaitingShipments(updatedShipments.filter(shipment => shipment.route_id === null));
  };

  const handleReassociate = async (shipmentId, routeId) => {
    await updateShipmentRoute(shipmentId, routeId);
    const updatedShipments = shipments.map(shipment =>
      shipment.id === shipmentId ? { ...shipment, route_id: routeId } : shipment
    );
    setShipments(updatedShipments);
    setWaitingShipments(updatedShipments.filter(shipment => shipment.route_id === null));
  };

  const handleLogout = async () => {
    try {
      await logout();
      history.push('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const openShipmentModal = (shipment) => {
    setCurrentShipment(shipment);
    setEditedDescription(shipment.description);
    setEditedRouteId(shipment.route_id);
    setEditedDirection(shipment.direction);
    setShipmentModalIsOpen(true);
  };

  const closeShipmentModal = () => {
    setShipmentModalIsOpen(false);
    setCurrentShipment(null);
  };

  const handleSaveShipmentEdit = async () => {
    if (currentShipment) {
      await updateShipment(currentShipment.id, editedDescription, editedRouteId, editedDirection);
      const updatedShipments = shipments.map(shipment =>
        shipment.id === currentShipment.id ? { ...shipment, description: editedDescription, route_id: editedRouteId, direction: editedDirection } : shipment
      );
      setShipments(updatedShipments);
      setWaitingShipments(updatedShipments.filter(shipment => shipment.route_id === null));
      closeShipmentModal();
    }
  };

  const handleDeleteShipment = async () => {
    if (currentShipment) {
      await deleteShipment(currentShipment.id);
      const updatedShipments = shipments.filter(shipment => shipment.id !== currentShipment.id);
      setShipments(updatedShipments);
      setWaitingShipments(updatedShipments.filter(shipment => shipment.route_id === null));
      closeShipmentModal();
    }
  };

  const openDateModal = () => {
    setDateModalIsOpen(true);
  };

  const closeDateModal = () => {
    setDateModalIsOpen(false);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
    closeDateModal();
  };

  const openRouteModal = (route) => {
    setCurrentRoute(route);
    setEditedTripNumber(route.trip_number);
    setEditedDriverId(route.driver_id);
    setEditedTruckId(route.truck_id);
    setEditedTrailerId(route.trailer_id);
    setRouteModalIsOpen(true);
  };

  const closeRouteModal = () => {
    setRouteModalIsOpen(false);
    setCurrentRoute(null);
  };

  const handleSaveRouteEdit = async () => {
    if (currentRoute) {
      await updateRoute(currentRoute.id, editedTripNumber, editedDriverId, editedTruckId, editedTrailerId);
      const updatedRoutes = routes.map(route =>
        route.id === currentRoute.id ? { ...route, trip_number: editedTripNumber, driver_id: editedDriverId, truck_id: editedTruckId, trailer_id: editedTrailerId } : route
      );
      setRoutes(updatedRoutes);
      closeRouteModal();
    }
  };

  const handleExport = () => {
    const wb = XLSX.utils.book_new();

    // Create the routes sheet
    const routesData = routes.map(route => ({
      TripNumber: route.trip_number,
      Driver: getDriverName(route.driver_id),
      Truck: getTruckNumber(route.truck_id),
      Trailer: getTrailerNumber(route.trailer_id),
      Shipments: shipments.filter(shipment => shipment.route_id === route.id)
        .map(shipment => `${shipment.description} (Direction: ${shipment.direction})`)
        .join(', '),
    }));
    const wsRoutes = XLSX.utils.json_to_sheet(routesData);
    XLSX.utils.book_append_sheet(wb, wsRoutes, 'Routes');

    // Create the waiting shipments sheet
    const waitingShipmentsData = waitingShipments.map(shipment => ({
      Description: shipment.description,
      Direction: shipment.direction,
    }));
    const wsWaitingShipments = XLSX.utils.json_to_sheet(waitingShipmentsData);
    XLSX.utils.book_append_sheet(wb, wsWaitingShipments, 'Waiting Shipments');

    // Export the workbook
    XLSX.writeFile(wb, `Dashboard_${selectedDate}.xlsx`);
  };

  if (!isAuthenticated) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <nav>
        <ul>
          <li><button onClick={handleLogout} className="nav-button no-print">Logout</button></li>
          <li><Link to="/drivers" className="nav-link no-print">Manage Drivers</Link></li>
          <li><Link to="/trucks" className="nav-link no-print">Manage Trucks</Link></li>
          <li><Link to="/trailers" className="nav-link no-print">Manage Trailers</Link></li>
          <li><Link to="/routes" className="nav-link no-print">Manage Routes</Link></li>
          <li><Link to="/shipments" className="nav-link no-print">Manage Shipments</Link></li>
          <li><button onClick={handlePrint} className="nav-button no-print">Print Dashboard</button></li>
          <li><button onClick={openDateModal} className="nav-button no-print">Date</button></li>
          <li><button onClick={handleExport} className="nav-button no-print">Export</button></li>
        </ul>
      </nav>
      <div className="print-section">
        <h2>Routes</h2>
        <div className="routes-grid">
          {routes.map(route => (
            <div key={route.id} className="route-card" onDoubleClick={() => openRouteModal(route)}>
              <h3>Trip #{route.trip_number}</h3>
              <p>Driver: {getDriverName(route.driver_id)}</p>
              <p>Truck: {getTruckNumber(route.truck_id)}</p>
              <p>Trailer: {getTrailerNumber(route.trailer_id)}</p>
              <h4>Northbound Shipments</h4>
              <ul>
                {shipments.filter(shipment => shipment.route_id === route.id && shipment.direction === 'northbound').map(filteredShipment => (
                  <li key={filteredShipment.id} onDoubleClick={() => openShipmentModal(filteredShipment)}>
                    {filteredShipment.description}
                    <button onClick={() => handleDisassociate(filteredShipment.id)} className="button no-print">Disassociate</button>
                    <select onChange={(e) => handleSwitch(filteredShipment.id, e.target.value)} className="button no-print">
                      <option value="">Switch Route</option>
                      {routes.map(r => (
                        <option key={r.id} value={r.id}>Trip #{r.trip_number} - {getDriverName(r.driver_id)}</option>
                      ))}
                    </select>
                  </li>
                ))}
              </ul>
              <h4>Southbound Shipments</h4>
              <ul>
                {shipments.filter(shipment => shipment.route_id === route.id && shipment.direction === 'southbound').map(filteredShipment => (
                  <li key={filteredShipment.id} onDoubleClick={() => openShipmentModal(filteredShipment)}>
                    {filteredShipment.description}
                    <button onClick={() => handleDisassociate(filteredShipment.id)} className="button no-print">Disassociate</button>
                    <select onChange={(e) => handleSwitch(filteredShipment.id, e.target.value)} className="button no-print">
                      <option value="">Switch Route</option>
                      {routes.map(r => (
                        <option key={r.id} value={r.id}>Trip #{r.trip_number} - {getDriverName(r.driver_id)}</option>
                      ))}
                    </select>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="waiting-shipments form-group">
          <h2>Waiting Shipments</h2>
          <ul>
            {waitingShipments.map(shipment => (
              <li key={shipment.id} onDoubleClick={() => openShipmentModal(shipment)}>
                {shipment.description} (Direction: {shipment.direction})
                <select onChange={(e) => handleReassociate(shipment.id, e.target.value)} className="button no-print">
                  <option value="">Reassociate to Route</option>
                  {routes.map(route => (
                    <option key={route.id} value={route.id}>Trip #{route.trip_number} - {getDriverName(route.driver_id)}</option>
                  ))}
                </select>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <Modal
        isOpen={shipmentModalIsOpen}
        onRequestClose={closeShipmentModal}
        contentLabel="Edit Shipment"
      >
        <h2>Edit Shipment</h2>
        <form>
          <div className="form-group">
            <label>Description:
              <input type="text" value={editedDescription} onChange={(e) => setEditedDescription(e.target.value)} />
            </label>
          </div>
          <div className="form-group">
            <label>Route:
              <select value={editedRouteId || ''} onChange={(e) => setEditedRouteId(e.target.value)}>
                <option value="">Select Route</option>
                {routes.map(route => (
                  <option key={route.id} value={route.id}>Trip #{route.trip_number} - {getDriverName(route.driver_id)}</option>
                ))}
              </select>
            </label>
          </div>
          <div className="form-group">
            <label>Direction:
              <select value={editedDirection} onChange={(e) => setEditedDirection(e.target.value)} required>
                <option value="">Select Direction</option>
                <option value="northbound">Northbound</option>
                <option value="southbound">Southbound</option>
              </select>
            </label>
          </div>
        </form>
        <div className="form-group">
          <button onClick={handleSaveShipmentEdit} className="button">Save</button>
          <button onClick={handleDeleteShipment} className="button">Delete</button>
          <button onClick={closeShipmentModal} className="button">Cancel</button>
        </div>
      </Modal>

      <Modal
        isOpen={routeModalIsOpen}
        onRequestClose={closeRouteModal}
        contentLabel="Edit Route"
      >
        <h2>Edit Route</h2>
        <form>
          <div className="form-group">
            <label>Trip Number:
              <input type="text" value={editedTripNumber} onChange={(e) => setEditedTripNumber(e.target.value)} />
            </label>
          </div>
          <div className="form-group">
            <label>Driver:
              <select value={editedDriverId} onChange={(e) => setEditedDriverId(e.target.value)}>
                <option value="">Select Driver</option>
                {drivers.map(driver => (
                  <option key={driver.id} value={driver.id}>{driver.name}</option>
                ))}
              </select>
            </label>
          </div>
          <div className="form-group">
            <label>Truck:
              <select value={editedTruckId} onChange={(e) => setEditedTruckId(e.target.value)}>
                <option value="">Select Truck</option>
                {trucks.map(truck => (
                  <option key={truck.id} value={truck.id}>{truck.number}</option>
                ))}
              </select>
            </label>
          </div>
          <div className="form-group">
            <label>Trailer (Optional):
              <select value={editedTrailerId} onChange={(e) => setEditedTrailerId(e.target.value)}>
                <option value="">Select Trailer</option>
                {trailers.map(trailer => (
                  <option key={trailer.id} value={trailer.id}>{trailer.number}</option>
                ))}
              </select>
            </label>
          </div>
        </form>
        <div className="form-group">
          <button onClick={handleSaveRouteEdit} className="button">Save</button>
          <button onClick={closeRouteModal} className="button">Cancel</button>
        </div>
      </Modal>

      <Modal
        isOpen={dateModalIsOpen}
        onRequestClose={closeDateModal}
        contentLabel="Select Date"
      >
        <h2>Select Date</h2>
        <input type="date" value={selectedDate} onChange={handleDateChange} />
        <button onClick={closeDateModal} className="button">Close</button>
      </Modal>
    </div>
  );
}

export default Dashboard;
