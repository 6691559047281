import React, { useState, useEffect } from 'react';
import { getTrucks, createTruck } from '../services/api';

function TruckForm() {
  const [trucks, setTrucks] = useState([]);
  const [number, setNumber] = useState('');

  useEffect(() => {
    async function fetchTrucks() {
      const response = await getTrucks();
      setTrucks(response.data);
    }
    fetchTrucks();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await createTruck(number);
    setNumber('');
    const response = await getTrucks();
    setTrucks(response.data);
  };

  return (
    <div>
      <h1>Manage Trucks</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Number:
          <input type="text" value={number} onChange={(e) => setNumber(e.target.value)} />
        </label>
        <button type="submit">Add Truck</button>
      </form>
      <ul>
        {trucks.map(truck => (
          <li key={truck.id}>{truck.number}</li>
        ))}
      </ul>
    </div>
  );
}

export default TruckForm;
