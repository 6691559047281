// src/hooks/useAuth.js

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isAuthenticated } from '../services/auth';

function useAuth() {
  const [auth, setAuth] = useState(null);
  const history = useHistory();

  useEffect(() => {
    async function checkAuth() {
      const authenticated = await isAuthenticated();
      setAuth(authenticated);
      if (!authenticated) {
        history.push('/login');
      }
    }

    checkAuth();
  }, [history]);

  return auth;
}

export default useAuth;
