import axios from 'axios';

const API_URL = 'https://api.argustransport.com';

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

export const login = async (username, password) => {
  const response = await api.post('/login', { username, password });
  return response;
};

export const logout = () => {
  return api.post('/logout');
};

export const getDrivers = () => {
  return api.get('/drivers');
};

export const getTrucks = () => {
  return api.get('/trucks');
};

export const getTrailers = () => {
  return api.get('/trailers');
};

export const getRoutesByDate = (date) => {
  return api.get(`/routes`, {
    params: { date },
  });
};

export const updateRoute = (route_id, trip_number, driver_id, truck_id, trailer_id) => {
  return api.put(`/routes/${route_id}`, { trip_number, driver_id, truck_id, trailer_id });
};


export const getShipmentsByDate = (date) => {
  return api.get(`/shipments`, {
    params: { date },
  });
};

export const createDriver = (name) => {
  return api.post('/drivers', { name });
};

export const createTruck = (number) => {
  return api.post('/trucks', { number });
};

export const createTrailer = (number) => {
  return api.post('/trailers', { number });
};

export const createRoute = async (tripNumber, driverId, truckId, date, trailerId) => {
  return await api.post('/routes', {
    trip_number: tripNumber,
    driver_id: driverId,
    truck_id: truckId,
    date,
    trailer_id: trailerId,
  });
};

export const createShipment = async (description, date, routeId, direction) => {
  return await api.post('/shipments', {
    description,
    date,
    route_id: routeId,
    direction,
  });
};

export const updateShipmentRoute = (shipment_id, route_id) => {
  return api.put(`/shipments/${shipment_id}`, { route_id });
};

export const updateShipment = (shipment_id, description, route_id) => {
  return api.put(`/shipments/${shipment_id}`, { description, route_id });
};

export const deleteShipment = (shipment_id) => {
  return api.delete(`/shipments/${shipment_id}`);
};

export const checkAuth = () => {
  return api.get('/protected-route');
};
