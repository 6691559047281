import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { getShipmentsByDate, createShipment, getRoutesByDate, updateShipmentRoute, getDrivers } from '../services/api';
import './ShipmentForm.css';

Modal.setAppElement('#root');

function ShipmentForm() {
  const [shipments, setShipments] = useState([]);
  const [description, setDescription] = useState('');
  const [routeId, setRouteId] = useState(null);
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [direction, setDirection] = useState('');
  const [routes, setRoutes] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentShipmentId, setCurrentShipmentId] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchData(date);
  }, [date]);

  const fetchData = async (selectedDate) => {
    const shipmentsResponse = await getShipmentsByDate(selectedDate);
    setShipments(shipmentsResponse.data);

    const routesResponse = await getRoutesByDate(selectedDate);
    setRoutes(routesResponse.data);

    const driversResponse = await getDrivers();
    setDrivers(driversResponse.data);
  };

  const getDriverName = (driverId) => {
    const driver = drivers.find(driver => driver.id === driverId);
    return driver ? driver.name : 'Unknown';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!direction) {
      setError('Direction is required');
      return;
    }
    setError('');
    await createShipment(description, date, routeId, direction);
    setDescription('');
    setRouteId(null);
    setDirection('');
    setDate(new Date().toISOString().split('T')[0]);
    fetchData(date);
  };

  const openModal = (shipmentId) => {
    setCurrentShipmentId(shipmentId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentShipmentId(null);
  };

  const handleUpdateRoute = async () => {
    await updateShipmentRoute(currentShipmentId, routeId);
    closeModal();
    fetchData(date);
  };

  return (
    <div className="container">
      <h1>Manage Shipments</h1>
      <label>
        Date:
        <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
      </label>
      <form onSubmit={handleSubmit}>
        <label>
          Description:
          <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
        </label>
        <label>
          Route:
          <select value={routeId || ''} onChange={(e) => setRouteId(e.target.value || null)}>
            <option value="">Select Route</option>
            {routes.map(route => (
              <option key={route.id} value={route.id}>Trip #{route.trip_number} - {getDriverName(route.driver_id)}</option>
            ))}
          </select>
        </label>
        <label>
          Direction:
          <select value={direction} onChange={(e) => setDirection(e.target.value)} required>
            <option value="">Select Direction</option>
            <option value="northbound">Northbound</option>
            <option value="southbound">Southbound</option>
          </select>
        </label>
        {error && <p className="error">{error}</p>}
        <button type="submit">Add Shipment</button>
      </form>
      <ul>
        {shipments.map(shipment => (
          <li key={shipment.id}>
            {shipment.description} (Route: {shipment.route_id}, Direction: {shipment.direction})
            <button onClick={() => openModal(shipment.id)}>Update Route</button>
          </li>
        ))}
      </ul>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Update Route"
      >
        <h2>Select a Route</h2>
        <select value={routeId || ''} onChange={(e) => setRouteId(e.target.value || null)}>
          <option value="">Select Route</option>
          {routes.map(route => (
            <option key={route.id} value={route.id}>Trip #{route.trip_number} - {getDriverName(route.driver_id)}</option>
          ))}
        </select>
        <button onClick={handleUpdateRoute}>Update</button>
        <button onClick={closeModal}>Cancel</button>
      </Modal>
    </div>
  );
}

export default ShipmentForm;
