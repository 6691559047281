import React, { useState, useEffect } from 'react';
import { getDrivers, createDriver } from '../services/api';

function DriverForm() {
  const [drivers, setDrivers] = useState([]);
  const [name, setName] = useState('');

  useEffect(() => {
    async function fetchDrivers() {
      const response = await getDrivers();
      setDrivers(response.data);
    }
    fetchDrivers();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await createDriver(name);
    setName('');
    const response = await getDrivers();
    setDrivers(response.data);
  };

  return (
    <div>
      <h1>Manage Drivers</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </label>
        <button type="submit">Add Driver</button>
      </form>
      <ul>
        {drivers.map(driver => (
          <li key={driver.id}>{driver.name}</li>
        ))}
      </ul>
    </div>
  );
}

export default DriverForm;
