import React, { useState, useEffect } from 'react';
import { getTrailers, createTrailer } from '../services/api';

function TrailerForm() {
  const [trailers, setTrailers] = useState([]);
  const [number, setNumber] = useState('');

  useEffect(() => {
    async function fetchTrailers() {
      const response = await getTrailers();
      setTrailers(response.data);
    }
    fetchTrailers();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await createTrailer(number);
    setNumber('');
    const response = await getTrailers();
    setTrailers(response.data);
  };

  return (
    <div>
      <h1>Manage Trailers</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Number:
          <input type="text" value={number} onChange={(e) => setNumber(e.target.value)} />
        </label>
        <button type="submit">Add Trailer</button>
      </form>
      <ul>
        {trailers.map(trailer => (
          <li key={trailer.id}>{trailer.number}</li>
        ))}
      </ul>
    </div>
  );
}

export default TrailerForm;
