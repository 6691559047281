// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    padding: 2em;
  }
  
  h1 {
    color: var(--primary-color);
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    display: block;
    margin: 0.5em 0;
    color: var(--text-color);
  }
  
  input, select, button {
    padding: 0.5em;
    margin: 0.5em 0;
    border: 1px solid var(--accent-color);
    border-radius: 5px;
  }
  
  input, select {
    background-color: var(--input-background-color);
    color: var(--text-color);
  }
  
  button {
    background-color: var(--secondary-color);
    color: var(--button-text-color);
    cursor: pointer;
  }
  
  button:hover {
    background-color: var(--highlight-color);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/RouteForm.css"],"names":[],"mappings":"AAAA;IACI,YAAY;EACd;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,cAAc;IACd,eAAe;IACf,wBAAwB;EAC1B;;EAEA;IACE,cAAc;IACd,eAAe;IACf,qCAAqC;IACrC,kBAAkB;EACpB;;EAEA;IACE,+CAA+C;IAC/C,wBAAwB;EAC1B;;EAEA;IACE,wCAAwC;IACxC,+BAA+B;IAC/B,eAAe;EACjB;;EAEA;IACE,wCAAwC;EAC1C","sourcesContent":[".container {\r\n    padding: 2em;\r\n  }\r\n  \r\n  h1 {\r\n    color: var(--primary-color);\r\n  }\r\n  \r\n  form {\r\n    display: flex;\r\n    flex-direction: column;\r\n  }\r\n  \r\n  label {\r\n    display: block;\r\n    margin: 0.5em 0;\r\n    color: var(--text-color);\r\n  }\r\n  \r\n  input, select, button {\r\n    padding: 0.5em;\r\n    margin: 0.5em 0;\r\n    border: 1px solid var(--accent-color);\r\n    border-radius: 5px;\r\n  }\r\n  \r\n  input, select {\r\n    background-color: var(--input-background-color);\r\n    color: var(--text-color);\r\n  }\r\n  \r\n  button {\r\n    background-color: var(--secondary-color);\r\n    color: var(--button-text-color);\r\n    cursor: pointer;\r\n  }\r\n  \r\n  button:hover {\r\n    background-color: var(--highlight-color);\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
